import { RouterProvider } from 'react-router-dom';
import router from './constants/routes';
import { Toaster } from 'react-hot-toast';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Suspense, useCallback, useEffect, useLayoutEffect } from 'react';
import { database } from './utils/database';
import { Loading } from './components';
import SessionProvider from './providers/SessionProvider';
import { set, get, del } from 'idb-keyval';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { CACHE_SEVEN_DAYS } from './constants/variables';
import { useSessionStore } from './store/SessionStore';
import { v4 as uuid } from 'uuid';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      gcTime: CACHE_SEVEN_DAYS, // 7 days cache time
    },
  },
});

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie, removeCookie] = useCookies();
  const { user } = useSessionStore();

  useEffect(() => {
    if (user?.id) {
      const indexedDBPersister = {
        persistClient: async (client: unknown) => {
          try {
            await set(`naming-ai-${user?.id}`, client);
          } catch (error) {
            console.error('Failed to persist React Query cache:', error);
          }
        },
        restoreClient: async () => {
          try {
            const restored = await get(`naming-ai-${user?.id}`);
            return restored || undefined;
          } catch (error) {
            console.error('Failed to restore React Query cache:', error);
            return undefined;
          }
        },
        removeClient: async () => {
          try {
            await del(`naming-ai-${user?.id}`);
          } catch (error) {
            console.error('Failed to remove React Query cache:', error);
          }
        },
      };

      persistQueryClient({
        queryClient,
        persister: indexedDBPersister,
        buster: uuid(),
      });
    }
  }, [user?.id]);

  const refreshAccessToken = useCallback(async () => {
    try {
      const { error } = await database.auth.refreshSession();

      if (error) {
        removeCookie('access_token');
        removeCookie('refresh_token');
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error refreshing access_token:', error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSession = useCallback(async () => {
    try {
      const { data, error } = await database.auth.getSession();

      if (!data.session) {
        refreshAccessToken();
        return;
      }

      if (error) {
        removeCookie('access_token');
        removeCookie('refresh_token');
        return;
      }

      setCookie('access_token', data.session?.access_token, {
        maxAge: data.session?.expires_in,
        path: '/',
      });
      setCookie('refresh_token', data.session?.refresh_token, {
        path: '/',
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error getting session:', error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    getSession();
  }, [getSession]);

  return (
    <Suspense fallback={<Loading />}>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <SessionProvider>
            <RouterProvider router={router} />
            <Toaster />
          </SessionProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </Suspense>
  );
};

export default App;
